<template>
  <div id="user-group-list">
    <div class="vx-row">
      <div v-for="group in groups" :key="group.id"
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
          <vx-card :title="group.name" >
            <template slot="actions">
              <group-action :group="group" />
            </template>

            <div class="w-full pb-10">
              <h6 class="mb-2">{{ $t('administration.grouplist.roles.title') }}</h6>
              <vs-chip v-for="(translation, role) in roleTranslationByGroup(group)" :key="role"
                       class="flex justify-between text-lg">
                <vs-icon icon="supervisor_account" class="mr-1"/>
                {{ translation }}
              </vs-chip>
            </div>
          </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base cursor-pointer">
        <vx-card fixed-height="true">
          <template slot="no-body">
            <div class="bg-primary p-6" @click="newGroup">
              <div class="text-white text-center justify-between">
                  <feather-icon icon="PlusSquareIcon" svgClasses="h-32 w-32" />
              </div>
            </div>
          </template>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import moduleGroup from '@/store/group/moduleGroup'

import GroupAction from './GroupAction'

export default {
  components: {
    GroupAction
  },
  metaInfo: {
    title: 'Groups List'
  },
  computed: {
    groups () {
      return this.$store.state.group.groups
    }
  },
  methods: {
    roleTranslationByGroup (group) {
      const roleTranslation = {}
      group.roles.forEach((role) => {
        const messageKey = `administration.grouplist.roles.${role.toLowerCase()}`
        if (this.$te(messageKey)) {
          roleTranslation[role] = this.$t(messageKey)
        }
      })
      return roleTranslation
    },
    newGroup () {
      this.$router.push({name: 'user-group-add'})
    }
  },
  created () {
    // Register Module UserManagement Module
    if (!moduleGroup.isRegistered) {
      this.$store.registerModule('group', moduleGroup)
      moduleGroup.isRegistered = true
    }
    this.$store.dispatch('group/fetchGroups').catch(err => { console.error(err) })// eslint-disable-line no-console
  }
}
</script>

<style scoped>

</style>
